import { CSSProperties } from 'react';

export const h1: CSSProperties = {
  fontSize: '32px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '38px',
  letterSpacing: '0.64px',
};

export const h2: CSSProperties = {
  fontSize: '28px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '36px',
  letterSpacing: '0.56px',
};

export const h3: CSSProperties = {
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '30px',
  letterSpacing: '0.48px',
};

export const h4: CSSProperties = {
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '26px',
  letterSpacing: '0.4px',
};

export const h5: CSSProperties = {
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '28px',
  letterSpacing: '0.36px',
};

export const h6: CSSProperties = {
  fontSize: '15px',
  fontWeight: 600,
  lineHeight: '23px',
  letterSpacing: '0.02em',
};

export const subtitle1: CSSProperties = {
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '28px',
  letterSpacing: '0.36px',
};

export const subtitle2: CSSProperties = {
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  letterSpacing: '0.28px',
};

export const body1: CSSProperties = {
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: 'normal',
  letterSpacing: '0.02em',
};

export const body2: CSSProperties = {
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.3px',
};

export const largeButtonText: CSSProperties = {
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '22px',
  letterSpacing: '0.32px',
};

export const mediumButtonText: CSSProperties = {
  fontSize: '15px',
  fontWeight: 700,
  lineHeight: '20px',
  letterSpacing: '0.3px',
};

export const smallButtonText: CSSProperties = {
  fontSize: '13px',
  fontWeight: 700,
  lineHeight: '18px',
  letterSpacing: '0.26px',
};

export const captionText: CSSProperties = {
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '19px',
  letterSpacing: '0.02em',
};

export const overlineText: CSSProperties = {
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '18px',
  letterSpacing: '0.3px',
  textTransform: 'uppercase',
};

export const errorHelperText: CSSProperties = {
  color: '#A03137',
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: 1.66,
  letterSpacing: '0.03333em',
  textAlign: 'left',
};

export default {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  subtitle1,
  subtitle2,
  body1,
  body2,
  largeButtonText,
  mediumButtonText,
  smallButtonText,
  captionText,
  overlineText,
  errorHelperText,
};
