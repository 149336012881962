import React from 'react';
import * as Sentry from '@sentry/react';
import { InputProps, VisualType } from '../../types';
import SimpleTextField from './SimpleTextField';
import SimpleSelect from './SimpleSelect';
import ToggleButtonGroup from './ToggleButtonGroup';

export default function FieldInput(props: Readonly<InputProps>) {
  const { field } = props;
  const visualType = field.visual_type;

  if (visualType === VisualType.SimpleTextField)
    return <SimpleTextField {...props} />;
  else if (visualType === VisualType.SimpleSelect)
    return <SimpleSelect {...props} />;
  else if (visualType === VisualType.ToggleButtonGroup)
    return <ToggleButtonGroup {...props} />;

  // capture Sentry error
  const msg = `${field.type} [${field.visual_type}] is unknown field type.`;
  Sentry.captureException(msg);

  return <SimpleTextField {...props} />;
}
