import React from 'react';

import { StyledPanel } from 'src/Esg/styles';
import ValueChainEntity from './ValueChainEntity';
import NewValueChainEntity from './ValueChainEntity/AddNew';
import { EsgDmaValueChainEntityOutSchema } from '../../types';
import { Typography } from '@mui/material';
import { t } from 'i18next';

interface Props {
  entitiesData: EsgDmaValueChainEntityOutSchema[];
}

export default function ValueChainEntities({ entitiesData }: Readonly<Props>) {
  return (
    <>
      <Typography variant="body2" sx={{ p: '20px' }}>
        {t('esg.dma.step1.valueChain.subtitle')}
      </Typography>
      <StyledPanel>
        {entitiesData.map((entity) => (
          <ValueChainEntity key={entity.id} id={entity.id} />
        ))}
        <NewValueChainEntity buttonSx={{ m: '20px' }} />
      </StyledPanel>
    </>
  );
}
