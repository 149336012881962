import React, { ChangeEvent } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { InputProps } from '../../types';

import { useQv3Context } from 'src/QuestionnairesV3/hooks';
import SampleAnswer from 'src/QuestionnairesV3/components/SampleAnswer';

export default function SimpleTextField(props: Readonly<InputProps>) {
  const { value, onChange, field } = props;
  const { options } = useQv3Context();
  const hints = options?.hints?.[field.id];
  return (
    <>
      <Autocomplete
        freeSolo
        options={hints || []}
        value={value}
        onChange={(event, value) => onChange?.(value, event as ChangeEvent)}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            multiline
            label={field.label}
            onChange={(e) => onChange?.(e.target.value, e)}
            // error={!!errorMessage}
            // helperText={errorMessage}
            // disabled={disabled}
            sx={{
              mb: field.placeholder ? '12px' : '32px',
              '& .MuiOutlinedInput-root': {
                backgroundColor: value ? '#F2F2F2' : 'inherit',
              },
            }}
          />
        )}
      />
      <SampleAnswer placeholder={field.placeholder} />
    </>
  );
}
