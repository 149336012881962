import { QueryOptions, useResourceController } from 'src/common/hooks';
import {
  FieldAnswerInSchema,
  FieldAnswerOutSchema,
  FieldFilterSchema,
  FieldOptionFilterSchema,
  FieldOptionOutSchema,
  FieldOutSchema,
  QuestionAnswerInSchema,
  QuestionAnswerOutSchema,
  QuestionFilterSchema,
  QuestionnaireFilterSchema,
  QuestionnaireOutSchema,
  QuestionOutSchema,
} from './types';
import { useContext } from 'react';
import { Qv3Context } from './context';

export const useQv3Context = () => useContext(Qv3Context);

export const useQuestionnaires = (
  queryParams: Partial<QuestionnaireFilterSchema> = {},
  queryOptions: QueryOptions<QuestionnaireOutSchema> | undefined = undefined
) => {
  return useResourceController<undefined, QuestionnaireOutSchema>(
    `/api-questionnaires/questionnaires`,
    ['qv3', 'questionnaires', queryParams],
    queryParams,
    queryOptions,
    ['GET']
  );
};

export const useQuestions = (
  queryParams: Partial<QuestionFilterSchema> = {},
  queryOptions: QueryOptions<QuestionOutSchema> | undefined = undefined
) =>
  useResourceController<undefined, QuestionOutSchema>(
    `/api-questionnaires/questions`,
    ['qv3', 'questions', queryParams],
    queryParams,
    queryOptions,
    ['GET']
  );

export const useFields = (
  queryParams: Partial<FieldFilterSchema> = {},
  queryOptions: QueryOptions<FieldOutSchema> | undefined = undefined
) =>
  useResourceController<undefined, FieldOutSchema>(
    `/api-questionnaires/fields`,
    ['qv3', 'fields', queryParams],
    queryParams,
    queryOptions,
    ['GET']
  );

export const useFieldOptions = (
  queryParams: Partial<FieldOptionFilterSchema> = {},
  queryOptions: QueryOptions<FieldOptionOutSchema> | undefined = undefined
) =>
  useResourceController<undefined, FieldOutSchema>(
    `/api-questionnaires/field-options`,
    ['qv3', 'field-options', queryParams],
    queryParams,
    queryOptions,
    ['GET']
  );

export const useQuestionAnswers = (
  queryParams: Partial<QuestionAnswerOutSchema> = {},
  queryOptions: QueryOptions<QuestionAnswerOutSchema> | undefined = undefined
) =>
  useResourceController<QuestionAnswerInSchema, QuestionAnswerOutSchema>(
    `/api-questionnaires/question-answers`,
    ['qv3', 'question-answers', queryParams],
    queryParams,
    queryOptions
  );

export const useFieldAnswers = (
  queryParams: Partial<FieldAnswerOutSchema> = {},
  queryOptions: QueryOptions<FieldAnswerOutSchema> | undefined = undefined
) =>
  useResourceController<FieldAnswerInSchema, FieldAnswerOutSchema>(
    `/api-questionnaires/field-answers`,
    ['qv3', 'field-answers', queryParams],
    queryParams,
    queryOptions
  );
