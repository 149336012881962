import React, { useRef } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { toPng } from 'html-to-image';
import colors from 'src/theme/colors';
import { useLanguage } from 'src/common/hooks';

/* eslint-disable no-unused-vars */

interface RowData {
  upstream?: string;
  ownOperations?: string;
  downstream?: string;
}

interface ValueChainStakeholderTypesTableProps {
  rows: RowData[];
  renderCellContent: (
    typeName: string | undefined,
    nameToColor: { [key: string]: string }
  ) => React.ReactNode;
  tableTitle: string;
  nameToColor: { [key: string]: string };
}

export default function ValueChainStakeholderTypesTable({
  rows,
  renderCellContent,
  tableTitle,
  nameToColor,
}: Readonly<ValueChainStakeholderTypesTableProps>) {
  const { t } = useLanguage({ keyPrefix: 'esg.dma.step1.valueChain' });
  const tableRef = useRef<HTMLDivElement>(null);

  const handleDownload = async () => {
    if (tableRef.current) {
      try {
        const dataUrl = await toPng(tableRef.current, { pixelRatio: 2 });
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = `${tableTitle.replace(/\s+/g, '_')}.png`;
        link.click();
      } catch (error) {
        console.error('Failed to generate PNG:', error);
      }
    }
  };

  return (
    <Box
      sx={{
        margin: '36px 0px',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        minWidth: '1000px',
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        <IconButton onClick={handleDownload}>
          <DownloadIcon />
        </IconButton>
      </Box>
      {/* Area to capture */}
      <Box
        ref={tableRef}
        sx={{
          display: 'block',
          overflowX: 'auto',
          borderRadius: '16px',
          backgroundColor: colors.pureWhite,
          border: `1px solid ${colors.disabledGraphics}`,
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} sx={{ height: '70px' }} align="center">
                <Typography variant="h4">{tableTitle}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                sx={{ backgroundColor: colors.backgroundInput }}
              >
                <Typography variant="h5">{t('isUpstream')}</Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{ backgroundColor: colors.pureWhite }}
              >
                <Typography variant="h5">{t('isOwnOperations')}</Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{ backgroundColor: colors.backgroundInput }}
              >
                <Typography variant="h5">{t('isDownstream')}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                {/* Upstream */}
                <TableCell
                  sx={{
                    padding: '8px 0px 0px 0px',
                    borderBottom: 'unset',
                    backgroundColor: colors.backgroundInput,
                  }}
                  align="center"
                >
                  {renderCellContent(row.upstream, nameToColor)}
                </TableCell>
                {/* Own Operations */}
                <TableCell
                  align="center"
                  sx={{ padding: '8px 0px 0px 0px', borderBottom: 'unset' }}
                >
                  {renderCellContent(row.ownOperations, nameToColor)}
                </TableCell>
                {/* Downstream */}
                <TableCell
                  align="center"
                  sx={{
                    padding: '8px 0px 0px 0px',
                    borderBottom: 'unset',
                    backgroundColor: colors.backgroundInput,
                  }}
                >
                  {renderCellContent(row.downstream, nameToColor)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell sx={{ backgroundColor: colors.backgroundInput }} />
              <TableCell />
              <TableCell sx={{ backgroundColor: colors.backgroundInput }} />
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}
