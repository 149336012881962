import { useQuery } from 'react-query';
import { client } from 'src/utils/api-client';
import {
  EsgDmaImpactInSchema,
  EsgDmaImpactOutSchema,
  EsgDmaRiskOpportunityInSchema,
  EsgDmaRiskOpportunityOutSchema,
  EsgDmaStakeholdersAnswersAnalyticsSchema,
  EsgDmaSubtopicOutSchema,
  EsgDmaTopicHierarchySchema,
  EsgDmaTopicOutSchema,
  EsgStakeholderAnswerInSchema,
  EsgStakeholderAnswerOutSchema,
  EsgStakeholderDataInSchema,
  EsgStakeholderDataOutSchema,
  EsgStakeholderSurveyMetadataOutSchema,
  EsgDmaStakeholderQualityAnswerInSchema,
  EsgDmaStakeholderQualityAnswerOutSchema,
  EsgDmaValueChainEntityOutSchema,
  EsgDmaValueChainEntityInSchema,
  ValueChainEmployeesByCountryInSchema,
  ValueChainEmployeesByCountryOutSchema,
  ValueChainStakeholderTypeInSchema,
  ValueChainStakeholderTypeOutSchema,
  EsgDmaOutSchema,
  EsgDmaInSchema,
  EsgDmaRiskOpportunityMatterDataInSchema,
  EsgDmaRiskOpportunityMatterDataOutSchema,
  EsgDmaImpactMatterDataInSchema,
  EsgDmaImpactMatterDataOutSchema,
  EsgDatapointMaterialityOutSchema,
  EsgStakeholderSurveyOutSchema,
  EsgDmaMatrixDataSchema,
} from './types';
import { parseStages23Hierarchy } from './utils';
import {
  QueryOptions,
  useLanguage,
  useResourceController,
} from 'src/common/hooks';
import { UUID } from 'crypto';

export const useDmaHierarchy = () => {
  const { language } = useLanguage();
  const query = useQuery(['dmaHierarchy', language], () =>
    client
      .get<EsgDmaTopicHierarchySchema[]>('/web/esg/v3/dma-hierarchy')
      .then((res) => res.data)
  );

  const topics = query.data || [];

  // hierarchy for stages 2 and 3
  // is only 2 levels: TitleRow -> (SubtopicRow or SubSubTopicRow) -> ImpactRow
  const stages23Hierarchy = parseStages23Hierarchy(topics);

  return { topics, stages23Hierarchy, ...query };
};

export const useRiskOpportunities = (
  queryParams: Partial<EsgDmaRiskOpportunityOutSchema> = {},
  queryOptions:
    | QueryOptions<EsgDmaRiskOpportunityOutSchema>
    | undefined = undefined
) =>
  useResourceController<
    EsgDmaRiskOpportunityInSchema,
    EsgDmaRiskOpportunityOutSchema
  >(
    `/web/esg/v3/dma-risk-opportunities`,
    ['dma-risk-opportunities', queryParams],
    queryParams,
    queryOptions
  );

export const useDmaImpacts = (
  queryParams: Partial<EsgDmaImpactOutSchema> = {},
  queryOptions: QueryOptions<EsgDmaImpactOutSchema> | undefined = undefined
) =>
  useResourceController<EsgDmaImpactInSchema, EsgDmaImpactOutSchema>(
    `/web/esg/v3/dma-impacts`,
    ['dma-impacts', queryParams],
    queryParams,
    queryOptions
  );

export const useEsgStakeholderAnswer = (
  queryParams: Partial<EsgStakeholderAnswerOutSchema> = {},
  queryOptions:
    | QueryOptions<EsgStakeholderAnswerOutSchema>
    | undefined = undefined
) =>
  useResourceController<
    EsgStakeholderAnswerInSchema,
    EsgStakeholderAnswerOutSchema
  >(
    `/web/esg/v3/stakeholders-answers`,
    ['esg-stakeholders-survey', queryParams],
    queryParams,
    queryOptions
  );

export const useEsgSubTopic = () => {
  const { language } = useLanguage();
  const url = `/web/esg/v3/dma-hierarchy/subtopics`;
  const queryKey = ['esg', 'subtopics', language];

  const query = useQuery(queryKey, () =>
    client.get<EsgDmaSubtopicOutSchema[]>(url).then((res) => res.data)
  );
  const subTopics = query.data || [];
  const subTopicsMap = subTopics.reduce(
    (acc: Record<number, EsgDmaSubtopicOutSchema>, item) => {
      acc[item.id] = item;
      return acc;
    },
    {}
  );
  const stakeholderSurveySubtopics = subTopics.filter(
    (item) => item.is_stakeholder_survey
  );
  return { stakeholderSurveySubtopics, subTopics, subTopicsMap, ...query };
};

export const useEsgTopic = () => {
  const { language } = useLanguage();
  const url = `/web/esg/v3/dma-hierarchy/topics`;
  const queryKey = ['esg', 'topics', language];

  const query = useQuery(queryKey, () =>
    client.get<EsgDmaTopicOutSchema[]>(url).then((res) => res.data)
  );
  const topics = query.data || [];
  const topicsMap = topics.reduce(
    (acc: Record<number, EsgDmaTopicOutSchema>, item) => {
      acc[item.id] = item;
      return acc;
    },
    {}
  );
  return { topics, topicsMap, ...query };
};

export const useEsgStakeholderSurveyMetadata = () => {
  const url = `/web/esg/v3/stakeholders-survey/metadata`;
  const queryKey = ['esg', 'survey-metadata'];
  const query = useQuery(queryKey, () =>
    client
      .get<EsgStakeholderSurveyMetadataOutSchema>(url)
      .then((res) => res.data)
  );
  const metadata = query.data || ({} as EsgStakeholderSurveyMetadataOutSchema);
  return { metadata, ...query };
};

export const useEsgStakeholderSurveys = (
  queryParams: Partial<EsgStakeholderSurveyOutSchema> = {},
  queryOptions:
    | QueryOptions<EsgStakeholderSurveyOutSchema>
    | undefined = undefined
) =>
  useResourceController<undefined, EsgStakeholderSurveyOutSchema>(
    `/web/esg/v3/stakeholders-survey`,
    ['dma-stakeholder-surveys', queryParams],
    queryParams,
    queryOptions
  );

export const useEsgStakeholdersAnswersAnalytics = (
  esg_dma_id?: UUID,
  esg_stakeholder_id?: number
) => {
  const params: Record<string, any> = { esg_dma_id };
  if (esg_stakeholder_id) {
    params.esg_stakeholder_id = esg_stakeholder_id;
  }

  const url = `/web/esg/v3/stakeholders-answers-analytics`;
  const queryKey = [
    'stakeholders-answers-analytics',
    { esg_dma_id, esg_stakeholder_id },
  ];

  const query = useQuery(
    queryKey,
    () =>
      client
        .get<EsgDmaStakeholdersAnswersAnalyticsSchema>(url, { params })
        .then((res) => res.data),
    {
      enabled: !!esg_dma_id,
    }
  );

  const analytics = query.data || {
    responses_count: 0,
    surveyed_stakeholder_types_count: 0,
    surveyed_stakeholder_types: [],
    stakeholder_types_with_responses_count: 0,
    summary: [],
    topic_summary: [],
  };

  return { analytics, ...query };
};

export const useEsgStakeholderData = (
  queryParams: Partial<EsgStakeholderDataOutSchema> = {},
  queryOptions:
    | QueryOptions<EsgStakeholderDataOutSchema>
    | undefined = undefined
) =>
  useResourceController<
    EsgStakeholderDataInSchema,
    EsgStakeholderDataOutSchema
  >(
    `/web/esg/v3/stakeholders/data`,
    ['dma-stakeholders', queryParams],
    queryParams,
    queryOptions
  );

export const useDmaStakeholderQualityAnswers = (
  queryParams: Partial<EsgDmaStakeholderQualityAnswerInSchema> = {},
  queryOptions:
    | QueryOptions<EsgDmaStakeholderQualityAnswerOutSchema>
    | undefined = undefined
) =>
  useResourceController<
    EsgDmaStakeholderQualityAnswerInSchema,
    EsgDmaStakeholderQualityAnswerOutSchema
  >(
    `/web/esg/v3/quality-answers`,
    ['dma-quality-answers', queryParams],
    queryParams,
    queryOptions
  );

export const useDmaMatrix = (esgDmaId?: UUID, queryOptions: any = {}) => {
  const queryKey = ['dma-matrix', { esgDmaId }];
  const query = useQuery(
    queryKey,
    () =>
      client
        .get<EsgDmaMatrixDataSchema>(`web/esg/v3/dma-matrix/${esgDmaId}`)
        .then((res) => res.data),
    { ...queryOptions, enabled: !!esgDmaId || !!queryOptions?.enabled }
  );

  return {
    _data: query.data?.datapoints || [],
    _threshold: query.data?.threshold,
    ...query,
  };
};

export const useEsgDmaValueChainEntities = (
  queryParams: Partial<EsgDmaValueChainEntityOutSchema> = {},
  queryOptions:
    | QueryOptions<EsgDmaValueChainEntityOutSchema>
    | undefined = undefined
) =>
  useResourceController<
    EsgDmaValueChainEntityInSchema,
    EsgDmaValueChainEntityOutSchema
  >(
    `/web/esg/v3/dma-value-chain-entities`,
    ['dma-value-chain-entities', queryParams],
    queryParams,
    queryOptions
  );

export const useEsgDmaValueChainEmployeesByCountry = (
  queryParams: Partial<ValueChainEmployeesByCountryOutSchema> & {
    entity_id: number;
  }
) =>
  useResourceController<
    ValueChainEmployeesByCountryInSchema,
    ValueChainEmployeesByCountryOutSchema
  >(
    `/web/esg/v3/dma-value-chain-employees-by-country`,
    ['dma-value-chain-employees-by-country', queryParams],
    queryParams
  );

export const useEsgDmaValueChainStakeholderTypes = (entityId?: number) =>
  useResourceController<
    ValueChainStakeholderTypeInSchema,
    ValueChainStakeholderTypeOutSchema
  >(
    `/web/esg/v3/dma-value-chain-stakeholder-types`,
    ['dma-value-chain-stakeholder-types', { entityId }],
    { entity_id: entityId }
  );

export const useEsgDmas = (
  queryParams: Partial<EsgDmaOutSchema> & { esg_report_id?: number } = {},
  queryOptions:
    | QueryOptions<EsgDmaValueChainEntityOutSchema>
    | undefined = undefined
) =>
  useResourceController<EsgDmaInSchema, EsgDmaOutSchema>(
    `/web/esg/v3/dmas`,
    ['dmas', queryParams],
    queryParams,
    queryOptions
  );

export const useRiskOpportunityMatterData = (
  queryParams: Partial<EsgDmaRiskOpportunityMatterDataInSchema> = {},
  queryOptions:
    | QueryOptions<EsgDmaRiskOpportunityMatterDataOutSchema>
    | undefined = undefined
) =>
  useResourceController<
    EsgDmaRiskOpportunityMatterDataInSchema,
    EsgDmaRiskOpportunityMatterDataOutSchema
  >(
    `/web/esg/v3/dma-risk-opportunity-matter-data`,
    ['dma-risk-opportunity-matter-data', queryParams],
    queryParams,
    queryOptions
  );

export const useImpactMatterData = (
  queryParams: Partial<EsgDmaImpactMatterDataInSchema> = {},
  queryOptions:
    | QueryOptions<EsgDmaImpactMatterDataOutSchema>
    | undefined = undefined
) =>
  useResourceController<
    EsgDmaImpactMatterDataInSchema,
    EsgDmaImpactMatterDataOutSchema
  >(
    `/web/esg/v3/dma-impact-matter-data`,
    ['dma-impact-matter-data', queryParams],
    queryParams,
    queryOptions
  );

export const useDmaMaterialDatapoints = (esgDmaId?: UUID) => {
  const queryKey = ['dma-material-datapoints', { esgDmaId }];
  const query = useQuery(
    queryKey,
    () =>
      client
        .get<
          EsgDatapointMaterialityOutSchema[]
        >(`web/esg/v3/dmas/${esgDmaId}/material-datapoints`)
        .then((res) => res.data),
    { enabled: !!esgDmaId }
  );
  return query;
};

export async function getEsgDmaValueChainStakeholderTypes(
  entityId: number
): Promise<ValueChainStakeholderTypeOutSchema[]> {
  const params = { entity_id: entityId };

  try {
    const response = await client.get<ValueChainStakeholderTypeOutSchema[]>(
      '/web/esg/v3/dma-value-chain-stakeholder-types',
      { params }
    );
    return response.data;
  } catch (error) {
    console.error(
      `Failed to fetch stakeholder types for entity ID ${entityId}:`,
      error
    );
    throw error;
  }
}
