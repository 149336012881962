import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import {
  BigNumbers,
  MainMetricsContainer,
  MainMetricsPanel,
  StyledTextLight,
} from './styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from 'chart.js';
import BarChart from './BarChart';
import StakeholdersImpactTable from './StakeholdersImpactTable';
import ScatterChart from './AnswersMatrix';
import { useParams } from 'react-router-dom';
import PageLoading from 'src/components/PageLoading';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import { dataLabelPlugin } from '../../../utils';
import { useEsgStakeholdersAnswersAnalytics } from 'src/Esg/Dmav2/hooks';
import SummaryTable from './SummaryTable';
import { useEsgReports } from 'src/Esg/hooks';
import CustomMuiSelect from 'src/components/CustomMuiSelect';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  dataLabelPlugin
);

export default function Dashboard() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg:dma.stakeholderAnalytics.dashboard',
  });
  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });

  const [selectedStakeholderTypeId, setSelectedStakeholderTypeId] = useState<
    number | undefined
  >(undefined);

  const { analytics, isLoading, isError } = useEsgStakeholdersAnswersAnalytics(
    report?.esg_dma_id,
    selectedStakeholderTypeId
  );

  if (isError) return <SomethingWentWrong />;
  if (isLoading) return <PageLoading />;

  const stakeholderOptions = analytics.surveyed_stakeholder_types.map(
    (type) => ({
      label: type.name,
      value: type.id.toString(),
    })
  );

  const handleStakeholderChange = (value: string) => {
    if (value === '') {
      setSelectedStakeholderTypeId(undefined);
    } else {
      setSelectedStakeholderTypeId(Number(value));
    }
  };

  return (
    <div style={{ fontStyle: 'Poppins !important' }}>
      <Typography variant="h1">{t('mainMetrics')}</Typography>
      <br />
      <br />
      {/* Dropdown for selecting stakeholder type */}
      {analytics.surveyed_stakeholder_types &&
        analytics.surveyed_stakeholder_types.length > 0 && (
          <CustomMuiSelect
            label=""
            options={[
              { label: t('allStakeholdersTypes'), value: '' },
              ...stakeholderOptions,
            ]}
            value={
              selectedStakeholderTypeId ? String(selectedStakeholderTypeId) : ''
            }
            onChange={(newValue) => {
              handleStakeholderChange(newValue);
            }}
          />
        )}
      <br />
      <br />

      <MainMetricsContainer>
        <MainMetricsPanel style={{ maxWidth: '30%' }}>
          <StyledTextLight>{t('sumOfReceivedAnswers')}</StyledTextLight>
          <BigNumbers>{analytics.responses_count}</BigNumbers>
        </MainMetricsPanel>
        {!selectedStakeholderTypeId && (
          <>
            <MainMetricsPanel>
              <StyledTextLight>{t('numOfTypesOfStakeholders')}</StyledTextLight>
              <BigNumbers>
                {analytics.surveyed_stakeholder_types_count}
              </BigNumbers>
            </MainMetricsPanel>
            <MainMetricsPanel>
              <StyledTextLight>
                {t('numOfStakeholdersWhoResponded')}
              </StyledTextLight>
              <BigNumbers>
                {analytics.stakeholder_types_with_responses_count}
              </BigNumbers>
            </MainMetricsPanel>
          </>
        )}
      </MainMetricsContainer>

      <ScatterChart esg_stakeholder_id={selectedStakeholderTypeId} />
      <br />
      <StakeholdersImpactTable esg_stakeholder_id={selectedStakeholderTypeId} />
      <br />
      <BarChart esg_stakeholder_id={selectedStakeholderTypeId} />
      <br />
      <SummaryTable esg_stakeholder_id={selectedStakeholderTypeId} />
    </div>
  );
}
