import React from 'react';
import { useTranslation } from 'react-i18next';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';

/* eslint-disable no-unused-vars */

export enum EsgValueChainViews {
  ENTITIES = 'entity',
  SUMMARY = 'summary',
}

interface Props {
  value: EsgValueChainViews;
  onChange: (
    event: React.MouseEvent<HTMLElement>,
    newValue: EsgValueChainViews
  ) => void;
}

export default function EsgValueChainToggle(props: Readonly<Props>) {
  // create our ToggleButtonGroup component?
  const { value, onChange } = props;
  const { t } = useTranslation();

  return (
    <ToggleButtonGroup
      value={value}
      onChange={onChange}
      sx={{ padding: '8px' }}
      exclusive
    >
      <ToggleButton
        sx={{ padding: '12px', height: 'fit-content' }}
        size="small"
        value={EsgValueChainViews.ENTITIES}
      >
        {t('esg.dma.step1.valueChain.questionnaire')}
      </ToggleButton>
      <ToggleButton
        sx={{ padding: '12px', height: 'fit-content' }}
        size="small"
        value={EsgValueChainViews.SUMMARY}
      >
        {t('esg.administrationPanel.toggle.answers')}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
